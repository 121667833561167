<template>
    <div class="solution2" id="jumpPoint">
        <div class="banner">
            <div class="banner_title">
                <p class="p1">压&nbsp;&nbsp;铸&nbsp;&nbsp;行&nbsp;&nbsp;业&nbsp;&nbsp;解&nbsp;&nbsp;决&nbsp;&nbsp;方&nbsp;&nbsp;案</p>
                <p class="p2">超 越 软 件 智 造 未 来</p>
            </div>
        </div>

        <div class="boxes1">
            <p class="pub_title">简介</p>
            <div class="center">
                <div class="left">
                    <img src="./../../assets/Solution2/img1.png" alt="">
                </div>
                <div class="right">
                    <div class="line"></div>
                    <div class="content">{{content1}}</div>
                    <div class="line"></div>
                </div>
            </div>
        </div>

        <div class="boxes2">
            <p class="pub_title">实现目标</p>
            <img src="./../../assets/Solution2/img2.png" alt="">
        </div>

        <div class="boxes3">
            <p class="pub_title">压铸行业全流程数字化管理融合</p>
            <div class="center">
                <div class="top">
                    <img src="./../../assets/Solution2/img3.png" alt="">
                </div>
                <div class="bottom">{{content2}}</div>
            </div>
        </div>

        <div class="boxes4">
            <p class="pub_title">原材料追溯</p>
            <div class="center">
                <div class="area1">
                    <img src="./../../assets/Solution2/img4.png" alt="">
                </div>
               <div class="area2">
                    <div v-for="(i,index) in 6" :key="index" class="per_tip">交接班及生产现场报工管理</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name:'Solution2',
    components:{
        
    },
    data(){
        return {
            content1:'压铸是一种金属铸造工艺，其特点是利用模具内腔对融化的金属施加高压。模具通常是用强度更高的合金加工而成的，这个过程有些类似注塑成型。大多数压铸铸件都是不含铁的，例如锌、铜、铝、镁、铅、锡以及铅锡合金以及它们的合金。根据压铸类型的不同，需要使用冷室压铸机或者热室压铸机。',
            content2:'通过精益化、自动化、信息化融合的只能制造落地，打造玻璃般透明的生产现场，实时掌握现场生产加工情况和质量状况，推动质量、成本、交期等各项管理指标的持续提升，更好地满足客户需求，推动公司长期发展。',
        }
    },

    created(){
        this.$nextTick(()=>{
            this.tojumpPoint()
        })
    },

    methods:{
        tojumpPoint(){
            document.getElementById('jumpPoint').scrollIntoView({
                behavior:'smooth',
                block:'start'
            })
        }
    },
}
</script>

<style scoped lang='scss'>
@import './Solution2.scss'
</style>
